import React, { useEffect } from "react"
import * as styles from "../../styles/music.module.css"

const Navbar = ({ updateCategory, state }) => {
  useEffect(() => {
    if (state) {
      document.getElementById("firstBtn").classList.toggle("active2")
    }
  }, [state])

  function changeStyle(ele) {
    document.getElementsByClassName("active2")[0].classList.toggle("active2")
    ele.target.classList.toggle("active2")
  }

  function update(ele, cat) {
    changeStyle(ele)
    updateCategory(cat)
  }

  return (
    <div>
      <nav className={styles.navbarMainbg}>
        <div>
          <ul>
            <li>
              <button onClick={e => update(e, "Fingerstyle")} id="firstBtn">
                Fingerstyle
              </button>
            </li>
            <li>
              <button onClick={e => update(e, "Weekly Fav")}>Weekly Fav</button>
            </li>
            <li>
              <button onClick={e => update(e, "My Playlist")}>
                My Playlist
              </button>
            </li>
            <li>
              <button onClick={e => update(e, "Midi")}>Midi</button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
