import React from "react"
import * as styles from "../../styles/music.module.css"

function Autoplay({ setAutoplay }) {
  function changeState() {
    let checkboxState = !document.getElementById("myCheckBox").checked
    setAutoplay(checkboxState)
  }

  return (
    <div className={styles.toggleSwitch}>
      <h1>Autoplay</h1>
      <label className={styles.switch2}>
        <input id="myCheckBox" type="checkbox" />
        <span
          role="button"
          tabIndex={0}
          onClick={() => changeState()}
          onKeyDown={() => changeState()}
          className={styles.slider + ` ` + styles.round}
        >
          {" "}
        </span>
      </label>
    </div>
  )
}

export default Autoplay
