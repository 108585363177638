// extracted by mini-css-extract-plugin
export var animatedBackground = "music-module--animatedBackground--3vFEX";
export var audioPlayerInfo = "music-module--audioPlayerInfo--y71om";
export var audioThumbnail = "music-module--audioThumbnail--fU-FW";
export var backPlayer = "music-module--backPlayer--vCHAK";
export var colAuto = "music-module--col-auto--5Sa3Q";
export var frontPlayer = "music-module--frontPlayer--PRdZq";
export var header = "music-module--header--8KEde";
export var headerDiv = "music-module--headerDiv--3xWSl";
export var hrStyle = "music-module--hrStyle--VukAk";
export var navbarMainbg = "music-module--navbarMainbg--0BWqO";
export var playAtFront = "music-module--playAtFront--qeyG-";
export var player = "music-module--player--HHSSk";
export var playerTop = "music-module--playerTop--s8J2x";
export var playlistDiv = "music-module--playlistDiv--rkGjN";
export var playlistInfo = "music-module--playlistInfo--d7I-A";
export var progress = "music-module--progress--6+7Q2";
export var repDiv = "music-module--repDiv--gACSN";
export var round = "music-module--round--Q66Xd";
export var shuffleDiv = "music-module--shuffleDiv--tbcdX";
export var slider = "music-module--slider--cK4I3";
export var switch2 = "music-module--switch2--2oHQu";
export var title = "music-module--title--HjLi0";
export var toggleSwitch = "music-module--toggleSwitch--DSNg+";