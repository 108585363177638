import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/music/header"
import Navbar from "../components/music/navbar"
import Content from "../components/music/content"
import Autoplay from "../components/music/autoplay"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "../styles/music.module.css"

const Music = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "music.md" }) {
        childrenMarkdownRemark {
          frontmatter {
            music {
              id
              title
              artist
              category
              duration
              file {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const { music } = data.file.childrenMarkdownRemark[0].frontmatter

  const [filteredData, setFilteredData] = useState(
    music.filter(key => key.category === "Fingerstyle")
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [filteredData])

  function updateCategory(cat) {
    let filtered = music.filter(key => key.category === cat)
    setFilteredData(filtered)
  }

  const [autoplay, setAutoplay] = useState(false)

  return (
    <Layout>
      <Seo title="Music" />
      <Header />

      <div className={styles.animatedBackground}>
        <Navbar updateCategory={updateCategory} state={true} />
        <Autoplay setAutoplay={setAutoplay} />
        <Content data={filteredData} autoplay={autoplay} />
      </div>
    </Layout>
  )
}

export default Music
