import React, { useState, useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { BsPlayFill } from "@react-icons/all-files/bs/BsPlayFill"
import * as styles from "../../styles/music.module.css"
import Controller from "./controller"

const Content = ({ data, autoplay }) => {
  var progress,
    timer,
    percent = 0,
    duration

  const [latestId, setLatestId] = useState("")
  const [playlist, setPlaylist] = useState([])

  useEffect(() => {
    if (data) {
      setLatestId("")
      let tempArray = []
      data.map(d => tempArray.push(d.id))
      setPlaylist(tempArray)
    }
  }, [data])

  const status = useRef(autoplay)

  useEffect(() => {
    status.current = autoplay
  }, [autoplay])

  // show large preview
  function showPlayer(e, id) {
    e !== "" && e.preventDefault()
    document.getElementById(id + `-front`).classList.toggle("hide")
    document.getElementById(id).currentTime = 0
    if (latestId !== "") {
      pauseAudio(latestId)
      document.getElementById(id + `-back`).classList.toggle("hide")
      document.getElementById(latestId + `-front`).classList.toggle("hide")
      document.getElementById(latestId + `-back`).classList.toggle("hide")
      setLatestId(id)
    } else {
      document.getElementById(id + `-back`).classList.toggle("hide")
      setLatestId(id)
    }
    progress = document.getElementById("progress-" + id)
    playAudio(id)
  }

  const playAudio = id => {
    let audio = document.getElementById(id)
    progress = document.getElementById("progress-" + id)
    if (audio) {
      audio.play()
      duration = audio.duration
      advance(duration, audio, id)
    }
  }

  const pauseAudio = id => {
    let audio2 = document.getElementById(id)
    if (audio2) {
      audio2.pause()
      clearTimeout(timer)
    }
  }

  // update progress bar
  const advance = function (duration, element, id) {
    let increment = 10 / duration
    percent = Math.min(increment * element.currentTime * 10, 100)
    progress && (progress.style.width = percent + "%")
    startTimer(duration, element, id)

    if (percent > 99) {
      pauseAudio(id)
      document.getElementById(`play-` + id).classList.contains("hide") &&
        document.getElementById(`pause-` + id).classList.toggle("hide") &&
        document.getElementById(`play-` + id).classList.toggle("hide")

      if (status.current) {
        let currentIndex = playlist.findIndex(x => x === id)
        let newIndex =
          currentIndex >= playlist.length - 1 ? 0 : currentIndex + 1
        let newElId = playlist[newIndex]
        document.getElementById(newElId + `FrontPlayBtn`).click()
      } else {
        element.currentTime = 0
      }
    }
  }

  const startTimer = function (duration, element, id) {
    if (percent < 100) {
      timer = setTimeout(function () {
        advance(duration, element, id)
      }, 100)
    }
  }

  return (
    <div className={styles.playlistDiv + ` mb-5`}>
      {data.map(d => (
        <div key={d.title}>
          {/* front */}
          <div
            id={d.id + `-front`}
            className={
              styles.frontPlayer +
              ` container d-flex align-items-center justify-content-center`
            }
          >
            <div className="col-auto">
              <button
                className={styles.playAtFront}
                id={d.id + `FrontPlayBtn`}
                onClick={e => showPlayer(e, d.id)}
              >
                <BsPlayFill />
              </button>
            </div>
            <div
              className={
                styles.playlistInfo +
                ` col-auto me-auto d-flex justify-content-center flex-column py-2`
              }
            >
              <h1>{d.title}</h1>
              <p className="m-0">{d.artist}</p>
            </div>
            <div className="col-auto" style={{ marginRight: "20px" }}>
              <p className="my-0">{d.duration}</p>
            </div>
          </div>

          {/* back */}
          <div
            className={styles.backPlayer + ` d-flex hide`}
            id={d.id + `-back`}
          >
            <div className={styles.audioThumbnail}>
              {/* <imgcover image="true" /> */}
              <StaticImage
                src="../../images/thumbnail/music.jpg"
                alt="A thumbnail"
              />
            </div>
            <div className={styles.audioPlayerInfo + ` d-flex flex-column`}>
              <div className={styles.title}>
                <h1>{d.title}</h1>
                <p className="m-0">{d.artist}</p>
              </div>
              <Controller
                audioId={d.id}
                audio={d.file.publicURL}
                playAudio={playAudio}
                pauseAudio={pauseAudio}
                latestId={latestId}
                showPlayer={showPlayer}
                playlist={playlist}
              />
            </div>
          </div>

          <hr className={styles.hrStyle} />
        </div>
      ))}
    </div>
  )
}

export default Content
