import React, { useEffect } from "react"
import { IoRepeat } from "@react-icons/all-files/io5/IoRepeat"
import { TiArrowShuffle } from "@react-icons/all-files/ti/TiArrowShuffle"
import { BsPlayFill } from "@react-icons/all-files/bs/BsPlayFill"
import { BsPauseFill } from "@react-icons/all-files/bs/BsPauseFill"
import { BiSkipNext } from "@react-icons/all-files/bi/BiSkipNext"
import { BiSkipPrevious } from "@react-icons/all-files/bi/BiSkipPrevious"
import * as styles from "../../styles/music.module.css"

const Controller = ({
  audioId,
  audio,
  playAudio,
  pauseAudio,
  latestId,
  showPlayer,
  playlist,
}) => {
  useEffect(() => {
    if (
      latestId &&
      !document.getElementById(`play-` + audioId).classList.contains("hide")
    ) {
      document.getElementById(`play-` + audioId).classList.toggle("hide")
      document.getElementById(`pause-` + audioId).classList.toggle("hide")
      pauseAudio(audioId)
    }
  }, [latestId, audioId, pauseAudio])

  // play and pause
  function togglePlay(e, state) {
    e.preventDefault()
    document.getElementById(`play-` + audioId).classList.toggle("hide")
    document.getElementById(`pause-` + audioId).classList.toggle("hide")

    if (state === "play") {
      let playPromise = playAudio(audioId)
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {})
          .catch(error => {
            console.log(error)
            alert("LOADING... PLEASE WAIT OR REFRESH THE PAGE")
            playAudio(audioId)
          })
      }
    } else {
      pauseAudio(audioId)
    }
  }

  // next, prev, and shuffle
  function skip(e, state) {
    e.preventDefault()
    let currentIndex = playlist.findIndex(x => x === audioId)
    let newIndex
    if (state === "next") {
      newIndex = currentIndex >= playlist.length - 1 ? 0 : currentIndex + 1
    } else if (state === "prev") {
      newIndex = currentIndex <= 0 ? playlist.length - 1 : currentIndex - 1
    } else {
      newIndex = generateRandom(playlist.length)
      while (newIndex === currentIndex) {
        newIndex = generateRandom(playlist.length)
      }
    }

    showPlayer(e, playlist[newIndex])
  }

  // repeat
  function repeat(e) {
    e.preventDefault()
    document.getElementById(latestId).currentTime = 0
  }

  // get random int
  function generateRandom(max) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  return (
    <div className={`d-flex flex-column`}>
      <div className={styles.playerTop + ` d-flex align-items-center`}>
        <div>
          <button className={styles.repDiv} onClick={e => repeat(e)}>
            <IoRepeat />
          </button>
        </div>
        <div>
          <button onClick={e => skip(e, "prev")}>
            <BiSkipPrevious />
          </button>
        </div>
        <div>
          <div className={styles.player}>
            <button
              className="hide"
              id={`play-` + audioId}
              onClick={e => togglePlay(e, "play")}
            >
              <BsPlayFill />
            </button>
            <button
              id={`pause-` + audioId}
              onClick={e => togglePlay(e, "pause")}
            >
              <BsPauseFill style={{ paddingLeft: "0" }} />
            </button>
          </div>
        </div>
        <div>
          <button onClick={e => skip(e, "next")}>
            <BiSkipNext />
          </button>
        </div>
        <div>
          <button
            className={styles.shuffleDiv}
            onClick={e => skip(e, "shuffle")}
          >
            <TiArrowShuffle />
          </button>
        </div>
      </div>

      <div>
        <div className={styles.progress} id={`progress-` + audioId}></div>
        <audio id={audioId} preload="auto">
          <source src={audio} />
          <track
            src="captions_en.vtt"
            kind="captions"
            srcLang="en"
            label="english_captions"
          ></track>
        </audio>
      </div>
    </div>
  )
}

export default Controller
