import React from "react"
import * as styles from "../../styles/music.module.css"

const Header = () => {
  return (
    <div className={styles.headerDiv}>
      <div className={styles.header}>
        <h1>Welcome to My Music Library</h1>

        <p>
          Here is where I put some of my fingerstyle guitar covers audio and
          midi that I created from scratch using GarageBand and Ableton Live. I
          also put my pop song playlist and my favorite pop songs of the week
          here, so you can listen to it while working or studying. Hope you
          enjoy it, Cheers!
        </p>
      </div>
    </div>
  )
}

export default Header
